<template>
  <section id="about">
    <div class="container">
      <div class="row" :class="(data.bannerExist) ? 'pt-3' : 'padding-top-no-banner'">
        <div :class="data.json.responsive['paragraph1']">
          <h2> {{ data.json['title'] }}</h2>
          <h4> {{ data.json['subtitle'] }}</h4>
          <p v-html="data.json['paragraph1']"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    data () {
      const cJson = this.$webData.components.find((e) => e.name === 'About');
      const portfolioName = this.$portfolioName;
      return {
        json: cJson,
        portfolioName: portfolioName,
        bannerExist: (this.$webData.components.find((e) => e.name === 'Banner') !== undefined)
      }
    }
  },
  mounted() {
    //console.log(this.bannerExist)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .padding-top-no-banner {
    padding-top: 80px !important;
  }
</style>
